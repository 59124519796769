import React from "react";
import { Routes, Route, Link, BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
// import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import PubSub from "@aws-amplify/pubsub";
import awsExports from "./aws-exports";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./App.css";
import Header from "./Header";
import Home from "./Home";
import Checkout from "./Checkout";
import Login from "./Login";
import Payment from "./Payment";
import Signup from "./Signup";
import Footer from "./Footer";

Amplify.configure(awsExports);
PubSub.configure(awsExports);

const promise = loadStripe(
  "pk_live_51LSH8bFNZe85cemDk82Mn0CUHpNKd70FA55EVHOqBZCMdhT21fJYVOMXUxbvhbsmiK3VasMLWKrtHIVOOoerKF8F00vCXfvxzK"
);

function App() {
  return (
    <div className="app">
      <div className="App">
        <Routes>
          <Route
            path="/app"
            element={
              <>
                <Header />
                <Home />
                <Footer />
              </>
            }
          />
          <Route path="/" element={<Login />} />
          <Route
            path="app"
            element={
              <>
                <Header />
                <Footer />
              </>
            }
          >
            <Route path="checkout" element={<Checkout />} />
            <Route path="payment" element={<Payment />} />
            {/* <Route
              path="payment"
              element={
                <Elements stripe={promise}>
                  <Payment />
                </Elements>
              }
            /> */}
          </Route>
          <Route path="signup" element={<Signup />} />
        </Routes>
      </div>
    </div>
  );
}

// export default withAuthenticator(App);
export default App;
