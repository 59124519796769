import React from "react";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import { getBasketTotal } from "./Reducer";
import "./Subtotal.css";

function Subtotal() {
    const navigate = useNavigate();
    const [{ basket }, dispatch] = useStateValue();

  return (
    <>
      <div className="subtotal">
      <p>
        Subtotal ({basket.length} items):{" "}
        <strong>{getBasketTotal(basket)}</strong>
      </p>
      <small className="subtotal__gift">
        <input type="checkbox" /> This order contains a gift
      </small>

      <button className="btn" onClick={ e => navigate('/app/payment') }>Proceed to Checkout</button>
    </div>
    </>
  );
}

export default Subtotal;
