export const initialState = {//typescript: basket is typed
    basket: [],
};

// Selector (for loop + incrementing + summing up + return it)
export function getBasketTotal (basket) {
     const sum = basket?.reduce((amount, item) => item.price + amount, 0);
     return sum.toFixed(2);
}

function reducer (state, action) {
    console.log(action);
    switch (action.type) {
        case 'ADD_TO_BASKET':
            return {
                ...state,
                basket: [...state.basket, action.item,],
            };

        case "REMOVE_FROM_BASKET":
            const index = state.basket.findIndex(
                (basketItem) => basketItem.id === action.id
            );

            let newBasket = [...state.basket];

            if (index >= 0) {
                newBasket.splice(index, 1);
            } else {
                console.warn(
                    `Cant remove product (id: ${action.id}) as its not in basket!`
                );
            }

            return {
                ...state,
                basket: newBasket
            }

        default:
            return state;
    }
};

export default reducer;