import React, { useEffect, useState, useRef } from "react";
import "./Product.css";
import { useStateValue } from "./StateProvider";

function Product({ id, title, image, price, rating }) {
  const [{ basket }, dispatch] = useStateValue();
  const [isActive, setIsActive] = useState(false);

  console.log(basket);

  function addToBasket(e) {
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        id: id,
        title: title,
        image: image,
        price: price,
        rating: rating,
      },
    });
    setIsActive(true);
    setTimeout(() => {setIsActive(false); console.log("Here is setTimeout")}, 1000);
  };

  return (
    <div className="Product">
      <div className="Product__Info">
        <p>{title}</p>
        <p className="Product__Price">
          <small>€</small>
          <strong>{price}</strong>
        </p>
        <div className="Product__Rating">
          {React.Children.toArray(
            Array(rating)
              .fill()
              .map((_, i) => <p>⭐</p>)
          )}
        </div>
      </div>
      <img src={image} alt="" />
      <button
        className={`btn bubbly-button + ${isActive ? "animate" : ""}`}
        onClick={addToBasket}
      >
        Add to Basket
      </button>
    </div>
  );
}

export default Product;
