import React from "react";
import "./Footer.css";

function Footer() {

    return(
        <div className="footer">
            <div className="about">
                <h2>About Us</h2>
            </div>
            <div className="customerCare">
                <h2>Customer Care</h2>
            </div>
            <div>
                <h2>Flourish Mobile App</h2>
            </div>
        </div>
    )
}

export default Footer;