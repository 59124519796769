import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import logo from "./assets/logo_shop.png";
import "./Header.css";
import { useStateValue } from "./StateProvider";
import cognitoUserPool from "./properties";

function Header() {
  const [{ basket }, dispatch] = useStateValue();
  const [name, setName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getUsername();
  });

  async function signOut() {
    try {
      const user = cognitoUserPool.getCurrentUser();
      if (user) {
        user.signOut();
        navigate("/");
        console.log("logged out: ");
      } else console.log("not logged in");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  async function getUsername() {
    try {
      let info = await Auth.currentSession();
      let userName = info.idToken.payload["email"];
      setName(userName);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className="header">
        <div className="header__logo">
          <Link to="/app">𝓕𝓵🌺𝓾𝓻𝓲𝓼𝓱</Link>
        </div>
        <div className="header__search">
          <input className="header__searchInput" type="text" />
          <SearchIcon className="header__searchIcon btn" />
        </div>

        {/* <div className="Header__Nav"> */}
          {/* <Link to="/login"> */}
          <div className="Header__Option">
            <span className="Header__OptionLineOne">Hallo {name}</span>
            <button className="btn" onClick={signOut}>
              Sign out
            </button>
            {/* <span className="Header__OptionLineTwo">Sign In</span> */}
          </div>
          {/* </Link> */}

          <Link to="checkout">
            <div className="Header__OptionBaseket">
              <ShoppingBasketIcon />
              <span className="Header__OptionLineTwo Header__BasketCount">
                {basket?.length}
              </span>
            </div>
          </Link>
        </div>
      {/* </div> */}
      <>
        <Outlet />
      </>
    </>
  );
}

export default Header;
