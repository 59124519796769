import React, { useState } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "./graphql/queries";
import "./Home.css";
import Product from "./Product";

function Home() {
  const [fetchedTitle, setFetchedTitle] = useState({
    data: { listTodos: { items: { title: "a" } } },
  });
  const [title, setTitle] = useState("");

  async function listTodo() {
    setFetchedTitle(await API.graphql(graphqlOperation(queries.listTodos)));
    console.log(
      "fetched: " + JSON.stringify(fetchedTitle.data["listTodos"].items[0])
    );
    if (typeof fetchedTitle.data["listTodos"].items[0] !== "undefined") {
      setTitle(Object.values(fetchedTitle.data["listTodos"].items[0].title));
    }
  }

  const productList = [
    {
      id: "12",
      title: "Traumfänger",
      price: 49.99,
      image: require("./assets/dreamcatcher.jpg"),
      rating: 4,
    },
    {
      id: "23",
      title: "Bild",
      price: 49.99,
      image: require("./assets/painting.jpg"),
      rating: 4,
    },
    {
      id: "34",
      title: "Druck",
      price: 9.99,
      image: require("./assets/fishes.jpg"),
      rating: 5,
    },
    {
      id: "45",
      title: "Kamm",
      price: 249.99,
      image: require("./assets/dreamcatcher.jpg"),
      rating: 3,
    },
    {
      id: "56",
      title: "Landschaft",
      price: 4.99,
      image: require("./assets/comb.jpg"),
      rating: 2,
    },
    {
      id: "67",
      title: "Klangschale",
      price: 0.99,
      image: require("./assets/landscape.jpg"),
      rating: 1,
    },
  ];

  return (
    <div className="Home">
      <button className="btn" onClick={listTodo}>
        read
      </button>
      <h1>{title}</h1>
      <div className="Home__Container">
        <img
          className="Home__Image"
          src={require("./assets/banner_shop_small.png")}
          alt=""
        />
      </div>

      <div className="home__row">
        {React.Children.toArray(
          productList.map((product) => {
            return (
              <Product
                title={product.title}
                price={product.price}
                image={product.image}
                rating={product.rating}
              />
            );
          })
        )}
      </div>
    </div>
  );
}

export default Home;
