import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "./Axios";
import { useStateValue } from "./StateProvider";
import CheckoutProduct from "./CheckoutProduct";
// import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { getBasketTotal } from "./Reducer";
import "./Payment.css";
import { Auth } from "aws-amplify";

function Payment() {
  const [{ basket }, dispatch] = useStateValue();
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState(true);

  // const stripe = useStripe();
  // const elements = useElements();
  const navigate = useNavigate();

  useEffect(() => {
    async function getClientSecret() {
      const res = await axios({
        method: "post",
        url: `/payment/create?total=${getBasketTotal(basket) * 100}`
      });

      setClientSecret(res.data.clientSecret);
    }

    getClientSecret();
  }, [basket])

  console.log("Secret: ", clientSecret);
  // async function handleSubmit(e) {
  //   e.preventDefault();
  //   setProcessing(true);

  //   const payload = await stripe.confirmCardPayment(clientSecret, {
  //     payment_method: { card: elements.getElement(CardElement)}
  //   }).then(({ paymentIntent }) => {
  //     setSucceeded(true);
  //     setError(null);
  //     setProcessing(false);

  //     navigate('/orders');
  //   })
  // }

  function handleChange(e) {
    setDisabled(e.empty);
    setError(e.error ? e.error.message : "");
  }

  return (
    <div className="payment">
      <div className="payment__container">
        <h1>
          Checkout (<Link to="/app/checkout">{basket?.length} items</Link>)
        </h1>
        <div className="payment__section">
          <div className="payment__title">
            <h3>Delivery Address</h3>
          </div>
          <div className="payment__address">
            {/* <p>{user?.email}</p> */}
            <p>Street 123</p>
            <p>Berlin</p>
          </div>
        </div>
        <div className="payment__section">
          <div className="payment__title">
            <h3>Review items and delivery</h3>
          </div>
          <div className="payment__items">
            {React.Children.toArray(
              basket.map((item) => (
                <CheckoutProduct
                  id={item.id}
                  title={item.title}
                  image={item.image}
                  price={item.price}
                  rating={item.rating}
                />
              ))
            )}
          </div>
        </div>
        <div className="payment__section">
          <div className="payment__title">
            <h3>Payment Method</h3>
          </div>
          <div className="payment__details">
            <form>
            {/* <form onSubmit={handleSubmit}> */}
              {/* <CardElement onChange={handleChange} /> */}
              <div className="payment__priceContainer">
                <h3>Order Total: {getBasketTotal(basket)} €</h3>
                <button className="btn" disabled={processing || disabled || succeeded}>
                  <span>
                    {processing ? <p>Processing</p> : "Buy Now"}
                  </span>
                </button>
              </div>
              {error && <div>{error}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
