import React, { useState } from "react";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import cognitoUserPool from "./properties";
// import PropTypes from "prop-types";
// import { Token } from "@mui/icons-material";

// async function loginUser(credentials) {
//   return fetch("http://localhost:3001/login", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(credentials),
//   }).then((data) => data.json());
// }

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState();
  const navigate = useNavigate();

  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: cognitoUserPool,
  });

  const authenticationData = {
    Username: email,
    Password: password,
  };

  function onSubmit(e) {
    e.preventDefault();

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    return cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (res) => {
        console.log("onSuccess:", res);
        navigate("/app");
      },
      onFailure: (err) => {
        console.error("onFailure:", err);
      },
    });
  }

  // const register = async (e) => {
  //   e.preventDefault();
    // navigate("/apps");

  //   const token = await loginUser({
  //     email,
  //     password,
  //   });
  //   setToken(token);
  // };

  return (
    <div className="login">
      <div className="login__container">
        <h1>SignIn</h1>
        <form onSubmit={onSubmit}>
          <h5>E-mail</h5>
          <input
            // type="text"
            // value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <h5>Password</h5>
          <input
            type="password"
            // value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <button type="submit" className="btn">
            Sign In
          </button>
        </form>

        <p>
          By signing-in you agree to Mona.Yoga's Conditions of Use & Sale.
          Please see our Privacy Notice, our Cookies Notice and our
          Intrest-Based Ads Notice.
        </p>

        <button className="btn" onClick={() => navigate("/signup")}>
          Create your yoga.mona account
        </button>
      </div>
    </div>
  );
}

export default Login;

// Login.propTypes = {
//   getToken: PropTypes.func.isRequired
// }
