import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import cognitoUserPool from "./properties";

// console.log("log:" + process.env.REACT_APP_USER);

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [perName, setName] = useState("");
  const attributeList = [];
  const navigate = useNavigate();

  // const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

  // const user = properties.UserPoolId;
  // const userProp = properties.ClientId;

  // useEffect(() => {
  //   console.log(properties.UserPool);
  // });

  // const dataName = {
  //   Name: "name",
  //   Value: perName,
  // };

  // const attributeName = new AmazonCognitoIdentity.CognitoUserAttribute(
  //   dataName
  // );

  // attributeList.push(attributeName);

  function onSubmit(e) {
    e.preventDefault();
    cognitoUserPool.signUp(email, password, attributeList, null, (err, res) => {
      if (err) console.log("Error: " + err);
      console.log("user created: " + "attributeList.perName " + res);
      // navigate("/");
    });
  }

  return (
    <div className="login">
      <div className="login__container">
        <h1>Create account</h1>
        {/* <h1>{process.env.REACT_APP_UserPoolId}</h1> */}
        <form onSubmit={onSubmit}>
          {/* <label>Your name</label>
          <input
            // type="text"
            value={perName}
            onChange={(e) => setName(e.target.value)}
          /> */}

          <label>Email</label>
          <input
            // type="text"
            // value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <label>Password</label>
          <input
            type="password"
            // value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="btn">
            SignUp
          </button>
        </form>
        <p>
          By signing-in you agree to Mona.Yoga's Conditions of Use & Sale.
          Please see our Privacy Notice, our Cookies Notice and our
          Interest-Based Ads Notice.
        </p>
        <div className="login__alternative">
          <hr />
          <p>Already have an account?</p>
          <button className="btn" onClick={() => navigate("/")}>
            SignIn
          </button>
        </div>
      </div>
    </div>
  );
}

export default Signup;
